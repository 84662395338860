import React, { useEffect }  from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import SEO from "../components/seo"
import RecipeRoll from "../components/RecipeRoll";

import AOS from 'aos';
import 'aos/dist/aos.css'

import FullWidthImage from "../components/FullWidthImage";



// eslint-disable-next-line
export const RecipePageTemplate = ({
  image,
  title,
}) => {
  useEffect(() => {
    AOS.init();
  }, [])
  const heroImage = getImage(image) || image;
  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} />
      <section className="section" >
          <div className="container">
            <div className="content">
              <RecipeRoll />
            </div>
          </div>
        </section>
    </div>

  );
};

RecipePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  meta :  PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const RecipePage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = data.markdownRemark;
  console.log(frontmatter.meta.title)


  console.log("tile log " + frontmatter.meta.title)
  return (
    <Layout>
      <SEO title={frontmatter.meta.title} description={frontmatter.meta.description} />
      <RecipePageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
      />
    </Layout>
  );
};

RecipePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RecipePage;

export const RecipePageQuery = graphql`
  query RecipePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        meta {
          title
          description
        }
      }
    }
  }
`;
